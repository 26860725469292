import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';

function useRole() {
  const [role, setRole] = useState('free');
  const { data: session, status } = useSession();

  useEffect(() => {
    try {
      if (status === 'unauthenticated' || session === null)
        return setRole('none');
      if (session.user.role === process.env.NEXT_PUBLIC_ADMIN_ROLE)
        return setRole('admin');
      if (session.user.role === process.env.NEXT_PUBLIC_UPLOADER_ROLE)
        return setRole('uploader');
      if (session.user.role === process.env.NEXT_PUBLIC_DESIGNER_ROLE)
        return setRole('designer');
      if (session.user.role === process.env.NEXT_PUBLIC_SITE_UPLOADER_ROLE)
        return setRole('siteUploader');
      else setRole('user');
    } catch (e) {
      setRole('user');
    }
  }, [session]);

  return { role };
}

export default useRole;
