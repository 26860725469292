import algoliasearch from 'algoliasearch';
import datasource from '../../datalayer';
import ApiService from 'services/ApiService';

export function getGraphUrl() {
  // Otherwise prepend the URL path with the Strapi URL
  return process.env.NEXT_PUBLIC_GRAPHQL;
}

export function getMediaUrl(url) {
  if (!url) {
    return null;
  }

  // Return the full URL if the media is hosted on an external provider
  if (url.startsWith('http') || url.startsWith('//')) {
    return url;
  }

  // Otherwise prepend the URL path with the Strapi URL
  return `${process.env.NEXT_PUBLIC_ASSETS_URL}/${url}`;
}

export function checkImgUrl(imgurl) {
  var url = imgurl;
  if (url == '') {
    url = '';
  } else {
    url = imgurl;
  }

  return `${process.env.NEXT_PUBLIC_ASSETS_URL}/${url}`;
}

export function checkUrl(url) {
  var field = url;
  var result = field.search(new RegExp(/^https:\/\//i));
  if (!result) {
    field = field;
  } else {
    field = 'https://' + field;
  }

  return field;
}

export const writeClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_WRITE_API_KEY,
);

export function cats(allcats) {
  var newlist = allcats.map(function (cat) {
    return { value: cat.cat_id, label: cat.category_name };
  });

  return newlist;
}

export async function designerproductmail(product, userdata, site_url) {
  const productdata = {
    id: product.id,
    product: product.title,
    slug: product.slug,
    username: userdata.user.first_name + ' ' + userdata.user.last_name,
    useremail: userdata.user.email,
    site_url,
  };

  await ApiService.instance.post(`/api/designerproductmail`, productdata);
}
